import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import './Gallery.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const MyGallery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');

  const allImages = [
    { id: 1, type: 'C25', url: '/uploads/galery/Voyage_C25 (1).jpg' },
    { id: 2, type: 'C25', url: '/uploads/galery/Voyage_C25 (2).jpg' },
    { id: 3, type: 'C25', url: '/uploads/galery/Voyage_C25 (3).jpg' },
    { id: 4, type: 'C25', url: '/uploads/galery/Voyage_C25 (4).jpg' },
    { id: 5, type: 'C25', url: '/uploads/galery/Voyage_C25 (5).jpg' },
    { id: 6, type: 'C27', url: '/uploads/galery/Voyage_C27 (1).jpg' },
    { id: 7, type: 'C27', url: '/uploads/galery/Voyage_C27 (2).jpg' },
    { id: 8, type: 'C27', url: '/uploads/galery/Voyage_C27 (3).jpg' },
    { id: 9, type: 'C27', url: '/uploads/galery/Voyage_C27 (4).jpg' },
    { id: 10, type: 'C27', url: '/uploads/galery/Voyage_C27 (5).jpg' },
    { id: 11, type: 'C30', url: '/uploads/galery/Voyage_C30 (1).jpg' },
    { id: 12, type: 'C30', url: '/uploads/galery/Voyage_C30 (2).jpg' },
    { id: 13, type: 'C30', url: '/uploads/galery/Voyage_C30 (3).jpg' },
    { id: 14, type: 'C30', url: '/uploads/galery/Voyage_C30 (4).jpg' },
    { id: 15, type: 'C30', url: '/uploads/galery/Voyage_C30 (5).jpg' },
    { id: 16, type: 'C301', url: '/uploads/galery/Voyage_C30(1,3м) (1).jpg' },
    { id: 17, type: 'C301', url: '/uploads/galery/Voyage_C30(1,3м) (2).jpg' },
    { id: 18, type: 'C301', url: '/uploads/galery/Voyage_C30(1,3м) (3).jpg' },
    { id: 19, type: 'C301', url: '/uploads/galery/Voyage_C30(1,3м) (4).jpg' },
    { id: 20, type: 'C301', url: '/uploads/galery/Voyage_C30(1,3м) (5).jpg' },
    { id: 21, type: 'C35', url: '/uploads/galery/Voyage_C35 (1).jpg' },
    { id: 22, type: 'C35', url: '/uploads/galery/Voyage_C35 (2).jpg' },
    { id: 23, type: 'C35', url: '/uploads/galery/Voyage_C35 (3).jpg' },
    { id: 24, type: 'C35', url: '/uploads/galery/Voyage_C35 (4).jpg' },
    { id: 25, type: 'C35', url: '/uploads/galery/Voyage_C35 (5).jpg' },
    { id: 26, type: 'C36', url: '/uploads/galery/Voyage_C36 (1).jpg' },
    { id: 27, type: 'C36', url: '/uploads/galery/Voyage_C36 (2).jpg' },
    { id: 28, type: 'C36', url: '/uploads/galery/Voyage_C36 (3).jpg' },
    { id: 29, type: 'C36', url: '/uploads/galery/Voyage_C36 (4).jpg' },
    { id: 30, type: 'C36', url: '/uploads/galery/Voyage_C36 (5).jpg' },
    { id: 31, type: 'Individual', url: "/uploads/galery/Voyage_X01_Individual (3).jpg"},
    { id: 32, type: 'Individual', url:   "/uploads/galery/Voyage_X01_Individual (4).jpg"},
    { id: 33, type: 'Individual', url:   "/uploads/galery/Voyage_X01_Individual (5).jpg"},
    { id: 49, type: 'Individual', url:   "/uploads/individual/5.jpeg"},
    { id: 50, type: 'Individual', url:   "/uploads/individual/6.jpeg"},
    { id: 34, type: 'Options', url:  "/uploads/galery/Опции_1.jpg",caption: 'Крепление канистры'},
    { id: 35, type: 'Options', url:  "/uploads/galery/Опции_2.jpg", caption: 'Навесной разворотный столик'},
    { id: 36, type: 'Options', url:  "/uploads/galery/Опции_3.jpg", caption: 'Крепление запасного колеса'},
    { id: 37, type: 'Options', url:  "/uploads/galery/Опции_4.jpg", caption: 'Крепление з/к с креплением лебедки'},
    { id: 38, type: 'Options', url:  "/uploads/galery/Опции_5.jpg", caption: 'Задние упоры (стойки) прицепа'},
    { id: 40, type: 'Options', url:  "/uploads/galery/Опции_6.jpg", caption: 'Подьемный каркас с тентом, окнами и задней подьемной дверью'},
    { id: 41, type: 'Options', url:  "/uploads/galery/Опции_7.jpg", caption: 'Тамбур навесной на заднюю дверь'},
    { id: 42, type: 'Options', url:  "/uploads/galery/Опции_8.jpg", caption: 'Боковой навес с упорами и растяжками'},
    { id: 43, type: 'Options', url:  "/uploads/galery/Опции_9_1.jpg", caption: 'Внешние/внутреннее автономное освещение'},
    { id: 44, type: 'Options', url:  "/uploads/galery/Опции_9_2.jpg", caption: 'Внешние/внутреннее автономное освещение'},
    { id: 45, type: 'Options', url:  "/uploads/galery/Опции_9_3.jpg", caption: 'Внешние/внутреннее автономное освещение'},
    { id: 46, type: 'Options', url:  "/uploads/galery/Опции_10.jpg", caption: 'Органайзеры на два окна'},
    { id: 47, type: 'Options', url:  "/uploads/galery/Опции_11_1.jpg", caption: 'Москитная сетка и мягкое окно на липучке'},
    { id: 48, type: 'Options', url:  "/uploads/galery/Опции_11_2.jpg", caption: 'Москитная сетка и мягкое окно на липучке'},
    { id: 52, type: 'All', url:  "/uploads/advantages/bl1/Adv_B1 (1).jpg"},
    { id: 53, type: 'All', url:  "/uploads/advantages/bl1/Adv_B1 (2).jpg"},
    { id: 54, type: 'All', url:  "/uploads/advantages/bl1/Adv_B1 (3).jpg"},
    { id: 55, type: 'All', url:  "/uploads/advantages/bl1/Adv_B1 (4).jpg"},
    { id: 56, type: 'All', url:  "/uploads/advantages/bl1/Adv_B1 (5).jpg"},
    { id: 57, type: 'All', url:  "/uploads/advantages/bl2/Adv_B2 (1).jpg"},
    { id: 58, type: 'All', url:  "/uploads/advantages/bl2/Adv_B2 (2).jpg"},
    { id: 59, type: 'All', url:  "/uploads/advantages/bl2/Adv_B2 (3).jpg"},
    { id: 60, type: 'All', url:  "/uploads/advantages/bl2/Adv_B2 (4).jpg"},
    { id: 61, type: 'All', url:  "/uploads/advantages/bl2/Adv_B2 (5).jpg"},
    { id: 62, type: 'All', url:  "/uploads/advantages/bl3/Adv_B3 (1).jpg"},
    { id: 63, type: 'All', url:  "/uploads/advantages/bl3/Adv_B3 (2).jpg"},
    { id: 64, type: 'All', url:  "/uploads/advantages/bl3/Adv_B3 (3).jpg"},
    { id: 65, type: 'All', url:  "/uploads/advantages/bl3/Adv_B3 (4).jpg"},
    { id: 66, type: 'All', url:  "/uploads/advantages/bl3/Adv_B3 (5).jpg"},
    { id: 67, type: 'All', url:  "/uploads/advantages/bl4/Adv_B4 (1).jpg"},
    { id: 68, type: 'All', url:  "/uploads/advantages/bl4/Adv_B4 (2).jpg"},
    { id: 69, type: 'All', url:  "/uploads/advantages/bl4/Adv_B4 (3).jpg"},
    { id: 70, type: 'All', url:  "/uploads/advantages/bl4/Adv_B4 (4).jpg"},
    { id: 71, type: 'All', url:  "/uploads/advantages/bl4/Adv_B4 (5).jpg"},

    { id: 72, type: 'All', url:  "/uploads/about/Грузоперевозки/1.jpg"},
    { id: 73, type: 'All', url:  "/uploads/about/Грузоперевозки/2.jpg"},
    { id: 74, type: 'All', url:  "/uploads/about/Грузоперевозки/3.jpg"},
    { id: 75, type: 'All', url:  "/uploads/about/Грузоперевозки/4.jpg"},
    { id: 76, type: 'All', url:  "/uploads/about/Грузоперевозки/5.jpg"},

    { id: 77, type: 'All', url:  "/uploads/about/Отдых/1.jpg"},
    { id: 78, type: 'All', url:  "/uploads/about/Отдых/2.jpg"},
    { id: 79, type: 'All', url:  "/uploads/about/Отдых/3.jpg"},
    { id: 80, type: 'All', url:  "/uploads/about/Отдых/4.jpg"},
    { id: 81, type: 'All', url:  "/uploads/about/Отдых/5.jpg"},
    { id: 82, type: 'All', url:  "/uploads/about/Отдых/6.jpg"},

    { id: 83, type: 'All', url:  "/uploads/about/Путешествия/1.jpg"},
    { id: 84, type: 'All', url:  "/uploads/about/Путешествия/2.jpg"},
    { id: 85, type: 'All', url:  "/uploads/about/Путешествия/3.jpg"},
    { id: 86, type: 'All', url:  "/uploads/about/Путешествия/4.jpg"},
    { id: 87, type: 'All', url:  "/uploads/about/Путешествия/5.jpg"},
  ];

    useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type) {
      setFilter(type);
    }
  }, [location]);

  const filteredImages =
    filter === 'all' ? allImages : allImages.filter((image) => image.type === filter);

  const handleFilterChange = (type) => {
    setFilter(type);
    navigate(`?type=${type}`);
  };

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className="gallery-container">
      {/* Кнопка закрытия */}
      <button className="close-button" onClick={handleClose} aria-label="Закрыть">
        &times;
      </button>

      <a href="/">
        <img src="/assets/images/logo.png" className="logotip" alt="VRE" />
      </a>
      <h2 className="gallery-title">Галерея с проектами</h2>
      <div className="filter-buttons">
        <button className="gallery-button" onClick={() => handleFilterChange('all')}>Все</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C25')}>C25</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C27')}>C27</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C30')}>C30</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C301')}>С30(1300мм)</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C35')}>C35</button>
        <button className="gallery-button" onClick={() => handleFilterChange('C36')}>C36</button>
        <button className="gallery-button" onClick={() => handleFilterChange('Individual')}>Individual</button>
        <button className="gallery-button" onClick={() => handleFilterChange('Options')}>Опции</button>
      </div>
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]} elementClassNames="gallery-grid">
  {filteredImages.map((image) => (
    <a key={image.id} href={image.url} className="gallery-item">
      <img src={image.url} alt={`${image.id}`} className="gallery-image" />
      <div className="gallery-caption">{image.caption}</div>
    </a>
  ))}
</LightGallery>

    </div>
  );
};

export default MyGallery;